import cn from 'classnames'
import Link from 'next/link'
import React from 'react'

export type ButtonProps = {
  title: string
  kind: 'outlined' | 'green'
  route?: string
  link?: string
  scroll?: string
}

type Props = {
  data: ButtonProps
  darkMode?: boolean
  className?: string
  style?: React.CSSProperties
}

const Button = (props: Props) => {
  const { title, kind, route, link, scroll } = props.data
  const href = route || link || scroll || '#'
  const target = link && !route ? '_blank' : undefined

  return (
    <Link legacyBehavior href={href} target={target}>
      <a
        target={target}
        className={cn('button group flex items-center py-1 px-4 rounded-full transition-colors', props.className, {
          'bg-green-500 hover:bg-green-600 text-black': kind === 'green',
          'border border-black text-black hover:bg-opacity-5 hover:bg-black': kind === 'outlined' && !props.darkMode,
          'border border-white text-white hover:bg-opacity-5 hover:bg-white': kind === 'outlined' && props.darkMode,
        })}
      >
        <span>{title}</span>
        <svg
          width="98"
          height="14"
          viewBox="0 0 98 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="ml-4 transition-all origin-left group-hover:scale-x-105"
        >
          {route || link ? (
            <path
              d="M0.761658 12.5127H85.7617L97.2617 1.0127M97.2617 1.0127V13.0127M97.2617 1.0127H85.2617"
              stroke={kind === 'outlined' && props.darkMode ? 'white' : 'black'}
            />
          ) : (
            <path
              d="M0.5 0.72168H85.5L97 12.2217M97 12.2217V0.22168M97 12.2217H85"
              stroke={kind === 'outlined' && props.darkMode ? 'white' : 'black'}
            />
          )}
        </svg>
      </a>
    </Link>
  )
}

export default Button
