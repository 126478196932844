import * as React from 'react'

import { fetchPublicListings, fetchPublicListingsWithFilter } from '../graphql/queries'
import sanity from '../sanity'
import { sanityThreeListingsQuery } from '../sanity/listing'
import { shuffleArray } from '../utils/utils'
import Button from './Button'
import ListingCard from './ListingCard'

type Props = {
  data: any
}

const ListingPreview = (props: Props) => {
  const { data } = props

  const [listings, setListings] = React.useState([])

  React.useEffect(() => {
    async function fetch() {
      const sanityOffers = await sanity.fetch<any[]>(sanityThreeListingsQuery)
      let listingsWithData = await fetchPublicListingsWithFilter(sanityOffers)
      listingsWithData = shuffleArray(listingsWithData).slice(0, 3)

      setListings(listingsWithData)
    }

    fetch()
  }, [])

  return (
    <section className="landing-offers my-20 flex flex-col items-center md:px-8">
      {data.title && <h2 className="text-2xl mb-8">{data.title}</h2>}

      <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 list-none p-0 m-0">
        {listings.map((el, i) => {
          return <ListingCard listing={el} key={i + el.slug} />
        })}
      </ul>

      {data.link && <Button data={data.link} className="mt-10" />}
    </section>
  )
}

export default ListingPreview
