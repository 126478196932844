import Link from 'next/link'
import * as React from 'react'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Image from 'next/image'

import imageUrlFor from '../utils/imageUrlFor'
import { serializeFuelType, serializeTransmissionType } from '../utils/serializeData'
import { formatPrice } from '../utils/utils'

type Props = {
  listing: any
}

const ListingCard = (props: Props) => {
  const { listing } = props
  const isAnyDiscount = listing?.data?.price !== listing?.data?.customer_price

  return (
    <li className="col-span-1 group p-4 md:p-6 bg-gray-50">
      <a href={`/listing/${listing.slug.current}`}>
        <div className="w-full overflow-hidden aspect-w-5 aspect-h-4">
          {listing.data ? (
            listing.images?.length ? (
              <Swiper className="" spaceBetween={0} slidesPerView={1} navigation loop modules={[Navigation]}>
                {[listing.cover, ...listing.images].map((el, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <img
                        width={2400}
                        src={imageUrlFor(el).ignoreImageParams().width(2400).fit('fill').quality(75).format('webp').url()}
                        className="group-hover:scale-105 object-cover min-h-full transition duration-700 ease-out"
                      />
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            ) : listing.cover ? (
              <img
                src={imageUrlFor(listing.cover).ignoreImageParams().width(800).fit('fill').quality(75).format('webp').url()}
                alt={listing.title}
                className="group-hover:scale-105 object-cover min-h-full transition duration-700 ease-out"
              />
            ) : null
          ) : (
            <div className="object-cover transition duration-700 ease-out animate-pulse bg-gray-300"></div>
          )}
        </div>

        <div className="mt-2 pb-2 flex justify-between items-center">
          {listing.data ? (
            <h3 className="max-w-3/5 pr-2">{listing.title}</h3>
          ) : (
            <div className="animate-pulse h-4 w-3/5 bg-gray-200 rounded-full"></div>
          )}

          {listing.data?.price ? (
            <h4 className="text-right">{formatPrice(listing.data.customer_price)} pr. 30. dag</h4>
          ) : (
            <div className="animate-pulse h-4 w-1/3 bg-gray-200 rounded-full"></div>
          )}
        </div>

        <div className="mb-2 pb-2 flex justify-between items-center border-b border-solid border-black">
          <div className="flex justify-between w-full">
            {listing.data?.dealership?.address ? (
              <h5 className="max-w-3/5 pr-2 text-neutral-500 text-sm">Afhentning i {listing.data.dealership.address.city}</h5>
            ) : (
              <div className="animate-pulse h-4 w-3/5 bg-gray-200 rounded-full"></div>
            )}
            {isAnyDiscount && (
              <h5 className="max-w-3/5 pr-2 text-neutral-500 text-sm line-through">
                {formatPrice(listing.data.price)} pr. 30. dag
              </h5>
            )}
          </div>
        </div>

        <div className="equipmentList py-4 flex justify-around text-sm">
          {listing.data ? (
            <>
              <div className="flex flex-col items-center">
                <svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mb-4">
                  <path
                    d="M9.5 23C4.80555 23 1 19.2147 1 14.5453C1 6.7125 9.5 1 9.5 1C9.5 1 18 6.71165 18 14.5444C18 19.2139 14.1945 22.9991 9.5 22.9991"
                    stroke="black"
                    strokeLinejoin="bevel"
                  />
                </svg>

                <p>{serializeFuelType(listing.data.vehicleModelVariant.fuelType)}</p>
              </div>

              <div className="flex flex-col items-center">
                <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg" className="mb-4">
                  <path
                    d="M19.6667 1V25M29 25V1M10.3333 25V1M1 1V13H28.9991M10.3333 1V25M19.6667 25V1"
                    stroke="black"
                    strokeLinecap="square"
                    strokeLinejoin="bevel"
                  />
                </svg>

                <p>{serializeTransmissionType(listing.data.vehicleModelVariant?.transmissionType)}</p>
              </div>

              <div className="flex flex-col items-center">
                <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg" className="mb-4">
                  <path
                    d="M17.3547 6.06733C17.3547 8.86603 15.1814 11.1347 12.5005 11.1347C9.81952 11.1347 7.64631 8.86603 7.64631 6.06733C7.64631 3.26864 9.81952 1 12.5005 1C15.1814 1 17.3547 3.26864 17.3547 6.06733ZM24 27C24 20.3698 18.8508 14.9945 12.4995 14.9945C6.14819 14.9945 1 20.3698 1 27H24Z"
                    stroke="black"
                    strokeMiterlimit="10"
                  />
                </svg>

                <p>{listing.data.vehicleModelVariant?.seats ? `${listing.data.vehicleModelVariant?.seats} sæder` : ''}</p>
              </div>

              <div className="flex flex-col items-center">
                <svg width="40" height="28" viewBox="0 0 368 313" fill="none" xmlns="http://www.w3.org/2000/svg" className="mb-4">
                  <path
                    d="m14.9 239v51.6c0 8.8 7.2 16 16.1 16h31.8c8.8 0 16-7.2 16-16v-21.4m274-30.2c5.7-6.7 8.9-15.3 8.9-24.2v-44.9c0-9.1-3.3-17.9-9.4-24.7l-23.3-22.8m-127 146.8h112.8m-261.8 0h119m-133.3-146.8l-23.3 22.8c-6.1 6.8-9.4 15.6-9.4 24.7v44.9c0 8.9 3.2 17.5 8.9 24.2m314.1-116.6c0 1.1-45.2 10.4-145.1 10.4-99.9 0-145.2-9.3-145.2-10.4m98.4 94.5h93.5m122.2 22.1v51.6c0 8.8-7.2 16-16.1 16h-31.7c-8.9 0-16.1-7.2-16.1-16v-21.4m-198.1-68.1c-9.8 0-35.7 2.7-49.5-20.6m235.6 20.6c9.8 0 35.7 2.7 49.5-20.6m-292.7-53.2l-23.9-12.3m324.3 12.3l23.8-12.3m-179.9-73.4c-35.1 0-64.1 0.6-84.8 5.2-11.4 2.6-21.1 7.9-26.7 18.6l-27.8 57m196.2-79.9c-12.4-0.6-19.3-0.8-33-0.9m127.1 80.8l-16.4-33.7"
                    stroke="black"
                    fill="none"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={12}
                  />
                  <path
                    d="m178 306.6v-37.4-227.6-34.8m151 45.7c-20 0-36.2-16.2-36.2-36.2 0 20-16.2 36.2-36.2 36.2 20 0 36.2 16.2 36.2 36.2 0-20 16.2-36.2 36.2-36.2z"
                    stroke="black"
                    fill="none"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={12}
                  />
                </svg>

                <p>{listing.data.consecutiveBookings ? 'Brugt' : 'Ny'}</p>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col items-center">
                <div className="animate-pulse rounded-full bg-gray-200 h-8 w-8"></div>
                <div className="animate-pulse h-2 w-16 bg-gray-200 rounded-full mt-4"></div>
              </div>

              <div className="flex flex-col items-center">
                <div className="animate-pulse rounded-full bg-gray-200 h-8 w-8"></div>
                <div className="animate-pulse h-2 w-16 bg-gray-200 rounded-full mt-4"></div>
              </div>

              <div className="flex flex-col items-center">
                <div className="animate-pulse rounded-full bg-gray-200 h-8 w-8"></div>
                <div className="animate-pulse h-2 w-16 bg-gray-200 rounded-full mt-4"></div>
              </div>

              <div className="flex flex-col items-center">
                <div className="animate-pulse rounded-full bg-gray-200 h-8 w-8"></div>
                <div className="animate-pulse h-2 w-16 bg-gray-200 rounded-full mt-4"></div>
              </div>
            </>
          )}
          {/* <EquipmentChip data={item} /> */}
        </div>
      </a>
    </li>
  )
}

export default ListingCard
